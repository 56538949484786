import React, { Component } from "react";
import AuthService from "../services/auth.service";
import "../assets/css/App.css"

export default class Logout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      loading: false,
      message: ""
    };
  }
  componentDidMount(){
    this.seDeconnecter = this.seDeconnecter.bind(this);
    this.seDeconnecter();
  }
  seDeconnecter(){
    AuthService.logout();
  }
  render() {
    return (
      <div className="col-md-12">
        <div className="card card-container">
          Heureux de vous revoir bientot!
        </div>
      </div>
    );
  }
}
