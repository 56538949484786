import { getMoi } from "api/crmAPI";
import axios from "axios";

const API_URL = "https://api.sodigaz.tg/public/graphql";
class AuthService {
  login(username, password) {
    return axios
      .post(API_URL +"?query=mutation{connecter(email:"+'"'+username+'"'+","+"password:"+'"'+password+'"'+")}")
      .then(response => {
        if (response.data.data.connecter) {
          localStorage.setItem("user", JSON.stringify({"userToken":response.data.data.connecter}));
          //this.getMe(response.data.data.connecter);
        }
        return response.data.data;
      });
  }

  logout() {
    localStorage.removeItem("user");
  }

  register(username, email, password) {
    return axios.post(API_URL + "signup", {
      username,
      email,
      password
    });
  }
  getMe(token){
    getMoi().then(data => {
      console.log(data);
      const jsonUser ={"userToken":token,"userID":data.id,"userRole":data.role,"userName":data.name};
      localStorage.setItem("user", JSON.stringify(jsonUser));
    })
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem('user'));;
  }
}

export default new AuthService();
