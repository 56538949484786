/*!

=========================================================
* Light Bootstrap Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import { Nav, NavDropdown, MenuItem } from "react-bootstrap";
import AuthService from "../../services/auth.service";
import { getMoi } from "api/crmAPI";
class AdminNavbarLinks extends Component {
  constructor(){
    super();
    this.state={
      "userConnected":{},
    }
  }
  componentDidMount(){
    const currentUser = AuthService.getCurrentUser();
    if(currentUser){
      getMoi().then(data => {
      console.log(data);
      const jsonUser ={"userToken":currentUser.userToken,"userID":data.id,"userRole":data.role,"userName":data.name};
      localStorage.setItem("user", JSON.stringify(jsonUser));
      this.setState({userConnected:jsonUser});
      })
    }
  }
  seDeconnecter(){
    AuthService.logout();
  }
  render() {
    const notification = (
      <div>
        <i className="fa fa-globe" />
        <b className="caret" />
        <span className="notification">5</span>
        <p className="hidden-lg hidden-md">Notification</p>
      </div>
    );
    const {userConnected} = this.state
    return (
      <div>
        <Nav pullRight>
          <NavDropdown
            eventKey={2}
            title={userConnected ? userConnected.userName:"TOTO"} 
            id="basic-nav-dropdown-right"
          >
            {/* <MenuItem eventKey={2.1}>
              Changer de mot de passe
            </MenuItem>
            <MenuItem divider /> */}
            <MenuItem eventKey={2.5} href="/logout">
              Se deconnecter
            </MenuItem>
          </NavDropdown>
        </Nav>
      </div>
    );
  }
}

export default AdminNavbarLinks;
