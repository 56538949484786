import moment from "moment";
import React, { Component} from "react"; 
import DataTable from 'react-data-table-component';
import AuthService from "services/auth.service";
import { getPDVGazVisites,getUsersByRole} from '../api/crmAPI';
import DatePicker from "react-date-picker";
import { CSVLink } from "react-csv";
import "react-datepicker/dist/react-datepicker.css";

const headers = [
      { label: "PDV Gaz", key: "nomPDV" },
      { label: "Observations", key: "observations" },
      { label: "Date du relevé de stock", key: "date_debut" },
      { label: "SDG, B6", key: "qsdg6" },
      { label: "SDG, B12", key: "qsdg12" },
      { label: "SDG, B50", key: "qsdg50" },
    ];
  const columns = [
  {
    name: 'PDV Gaz',
    selector: 'nomPDV',
    sortable: true,
  },
  {
    name: 'Observations',
    selector: 'observations',
    sortable: true,
  },
  {
    name: 'Date du relevé',
    selector: 'date_debut',
    sortable: true,
  },
  {
    name: 'SDG, B6',
    selector: 'qsdg6',
    sortable: true,
  },
  {
    name: 'SDG, B12',
    selector: 'qsdg12',
    sortable: true,

  },
  {
    name: 'SDG, B50',
    selector: 'qsdg50',
    sortable: true,

  },
];

class PDVReleves extends Component {
 
  constructor() {
    super();
    this.state = { 
      entrepots: [],
      startDate: "",
      endDate: "",
      idCommercial:1,
      currentUser: {},
      commerciaux:[]
    };
  }
  componentDidMount() {
    const currentUser = AuthService.getCurrentUser();
    const datedebut = moment().format("YYYY-MM-DD");
    console.log(datedebut);

    const zeroHeureAujourdui = datedebut+" 00:00:01";
    const finHeureAujourdhui = moment(zeroHeureAujourdui).subtract(15, 'days');
    const vfinHeureAujourdhui = finHeureAujourdhui.format("YYYY-MM-DD")+" 23:59:59";
    console.log(vfinHeureAujourdhui);
    this.setState({currentUser: currentUser});

    this._getPDVVisitesByDateByCommercial = this._getPDVVisitesByDateByCommercial.bind(this);
    this._getReleveByDate = this._getReleveByDate.bind(this);
    this._setIDCommercial = this._setIDCommercial.bind(this);
    this._setStartDate = this._setStartDate.bind(this);
    this._setEndDate = this._setEndDate.bind(this);
    this._onSubmitReleveStocks = this._onSubmitReleveStocks.bind(this);
    this._printCSVButton = this._printCSVButton.bind(this);
    this._printDataTable = this._printDataTable.bind(this);
    //this._onChange =  this._onChange.bind(this);
    this._listCommerciaux = this._listCommerciaux.bind(this);
    this._getListCommerciaux = this._getListCommerciaux.bind(this);
    if(currentUser){
      const periode ={"id": Number(currentUser.userID),"fin":vfinHeureAujourdhui,"debut":zeroHeureAujourdui};
      console.log(periode);
      this._getListCommerciaux();
      this._getPDVVisitesByDateByCommercial(periode);
    }
    
  }
  _getPDVVisitesByDateByCommercial(periode={}){
      getPDVGazVisites(periode).then(data => {
        console.log(data);
        //ata.filter((pdvreleve,index)=>pdvreleve == null);
        data.map((item, key)=>{
          let entrepotVisite = { 
            nomPDV: item.entrepot? item.entrepot.denomination:"-----", 
            date_debut:item.date_debut,
            observations: item.observations,
            qsdg6: item.qsdg6,
            qsdg12: item.qsdg12,
            qsdg50: item.qsdg50, 
          };
          let dataTemp = this.state.entrepots;
          dataTemp.push(entrepotVisite);
          this.setState({entrepots:[...dataTemp]});
        });
      })
      
  }
  _listCommerciaux(){
    const {commerciaux} = this.state;
    return(commerciaux.map((commercial,index) =>(
      <option value={commercial.id} key={commercial.id}>{commercial.name}</option>
    )))
  }
  _getReleveByCommercial(){
    const { currentUser } = this.state;
    if(Number(currentUser.userRole) >=3 ){
      return (
        <>
          <select onChange={this._setIDCommercial}>
            <option value="1">Par commercial</option>
            {this._listCommerciaux()}
          </select>
        </>
      )
    }
    
  }
  _getListCommerciaux(){
    getUsersByRole(2).then(data => {
      console.log(data);
      //data.filter((pdvreleve,index)=>pdvreleve == null);
      this.setState({commerciaux:[...data]});
    })
  }
  _onSubmitReleveStocks(){
    const { currentUser } = this.state;
    console.log("ID Commercial "+this.state.idCommercial);
    console.log("Date de debut: "+ moment(this.state.startDate).format("YYYY-MM-DD")+" 00:00:01");
    console.log("Date de fin: "+ moment(this.state.endDate).format("YYYY-MM-DD")+" 23:59:59");
    if(Number(currentUser.userRole) <=2 ){
      this.setState({ idCommercial: currentUser.userID }, () => {
        console.log("ID Commercial "+this.state.idCommercial);
        const periode ={"id":this.state.idCommercial,"fin": moment(this.state.endDate).format("YYYY-MM-DD")+" 23:59:59","debut":moment(this.state.startDate).format("YYYY-MM-DD")+" 00:00:01"};
        this._getPDVVisitesByDateByCommercial(periode);
          let dataTemp = this.state.entrepots;
          this.setState({entrepots:[...dataTemp]});
      });
    } else {
      console.log("Je suis ici");
      const periode ={"id":this.state.idCommercial,"fin": moment(this.state.endDate).format("YYYY-MM-DD")+" 23:59:59","debut":moment(this.state.startDate).format("YYYY-MM-DD")+" 00:00:01"};
      this.setState({entrepots: []});
      this._getPDVVisitesByDateByCommercial(periode);
      let dataTemp = this.state.entrepots;
      this.setState({entrepots: dataTemp});
      //.this.setState({entrepots: entrepots});
    }
    
  }
  _setIDCommercial(event=""){
    const { currentUser } = this.state;
    if(Number(currentUser.userRole) >=3 ){
      this.setState({ idCommercial: event.target.value }, () => {
        console.log("ID Commercial "+this.state.idCommercial);
      });
    } else {
      this.setState({ idCommercial: Number(currentUser.userID) }, () => {
        console.log("ID Commercial "+this.state.idCommercial);
      });
    }
    
  }
  _setStartDate(date){
    this.setState({startDate:date});
    //this._getPDVVisitesByDateByCommercial();
  }
  _setEndDate(date){
    this.setState({endDate:date});
    //this._getPDVVisitesByDateByCommercial();
  }

  _onChange(dates){
    const [start, end] = dates;
    console.log(moment(end).format("YYYY-MM-DD h:m:ss"));
    const stringDate = moment(end);
    if(moment.isDate(end)){
      console.log("Detect Invalid date");
      this._setEndDate(start);
    } else {
      this._setEndDate(end);
    }
    //this._setEndDate(end);
    this._setStartDate(start);
    this._getPDVVisitesByDateByCommercial();
    //console.log(moment(start).format("YYYY-MM-DD h:m:ss"));
    //console.log(moment(end).format("YYYY-MM-DD h:m:s"));
  }
  _getReleveByDate(){   
    return (
      <>
        <DatePicker onChange={(date) => this._setStartDate(date)} value={this.state.startDate}/>
        <DatePicker onChange={(date) => this._setEndDate(date)} value={this.state.endDate}/>
      </>
    );
  }

  _putValidationButton(){   
    return (
      <>
        <button type="submit" onClick={this._onSubmitReleveStocks}>Valider</button>
      </>
      
    );
  }

  _printCSVButton(){
    return (
      <CSVLink data={this.state.entrepots} headers={headers}>
          Exporter en excel
      </CSVLink>
    )
  }
  _printheaderComponent(){
    return (
    <div className="form-inline">
      {this._getReleveByCommercial()}
      {this._getReleveByDate()}
      {this._putValidationButton()}
    </div>
    )
  }
  _printDataTable(data){
    return(
      <DataTable
          columns={columns}
          data={data}
          pagination
        />
    )
  }
  render() {
    const {entrepots} = this.state;
    return (
      <div className="content">
        <>
          {this._printCSVButton()}
          {this._printheaderComponent()}
        </>
        {this._printDataTable(entrepots)}
      </div>
    )
  }
}
export default PDVReleves;