/*!

=========================================================
* Light Bootstrap Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import ChartistGraph from "react-chartist";
import { Grid, Row, Col } from "react-bootstrap";
import {Redirect} from "react-router-dom";
import { Card } from "components/Card/Card.jsx";
import { StatsCard } from "components/StatsCard/StatsCard.jsx";
import { Tasks } from "components/Tasks/Tasks.jsx";
import AuthService from "../services/auth.service";
import {getEffectifPDVGaz,getEffectifPDVReleve,getEffectifProspect,getEffectifProspections} from '../api/crmAPI';
import {
  dataPie,
  legendPie,
  dataSales,
  optionsSales,
  responsiveSales,
  legendSales,
  dataBar,
  optionsBar,
  responsiveBar,
  legendBar
} from "variables/Variables.jsx";

class Dashboard extends Component {
  constructor() {
    super();
    this.state={
      effectifPDVGaz:0,
      effectifPDVGazReleves:0,
      effectifProspects:0,
      effectifProspections:0,
      effectifProspections:0,
      redirect:null
    }
  }
  createLegend(json) {
    var legend = [];
    for (var i = 0; i < json["names"].length; i++) {
      var type = "fa fa-circle text-" + json["types"][i];
      legend.push(<i className={type} key={i} />);
      legend.push(" ");
      legend.push(json["names"][i]);
    }
    return legend;
  }
  componentDidMount() {
    const currentUser = AuthService.getCurrentUser();
    const list_pdvsdg =[0,1,2,3,4,5,11];
    getEffectifPDVGaz(list_pdvsdg).then(data => {
      this.setState({effectifPDVGaz: data});
    });
    getEffectifPDVReleve().then(data => {
      this.setState({effectifPDVGazReleves: data});
    });
    getEffectifProspect().then(data => {
      this.setState({effectifProspects: data});
    });
    getEffectifProspections().then(data => {
      this.setState({effectifProspections: data});
    });
    if (!currentUser) this.setState({ redirect: "/login" });
  }
  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }
    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col lg={3} sm={6}>
              <StatsCard
                bigIcon={<i className="pe-7s-server text-warning" />}
                statsText="PDV Gaz"
                statsValue={this.state.effectifPDVGaz.toString()}
                statsIcon={<i className="fa fa-refresh" />}
                statsIconText="Jusqu'alors"
              />
            </Col>
            <Col lg={3} sm={6}>
              <StatsCard
                bigIcon={<i className="pe-7s-wallet text-success" />}
                statsText="PDV relevés"
                statsValue={this.state.effectifPDVGazReleves.toString()}
                statsIcon={<i className="fa fa-calendar-o" />}
                statsIconText="Jusqu'alors"
              />
            </Col>
            <Col lg={3} sm={6}>
              <StatsCard
                bigIcon={<i className="pe-7s-graph1 text-danger" />}
                statsText="Prospects"
                statsValue={this.state.effectifProspects.toString()}
                statsIcon={<i className="fa fa-clock-o" />}
                statsIconText="Rencontrés"
              />
            </Col>
            <Col lg={3} sm={6}>
              <StatsCard
                bigIcon={<i className="fa fa-twitter text-info" />}
                statsText="Prospections"
                statsValue={this.state.effectifProspections.toString()}
                statsIcon={<i className="fa fa-refresh" />}
                statsIconText="Réalisées"
              />
            </Col>
          </Row>
          <Row>
            <Col md={8}>
              <Card
                statsIcon="fa fa-history"
                id="chartHours"
                title="Stocks de gaz relevés"
                category="Il y a 24h auprès de 05 PDV"
                stats="Updated 3 minutes ago"
                content={
                  <div className="ct-chart">
                    <ChartistGraph
                      data={dataSales}
                      type="Line"
                      options={optionsSales}
                      responsiveOptions={responsiveSales}
                    />
                  </div>
                }
                legend={
                  <div className="legend">{this.createLegend(legendSales)}</div>
                }
              />
            </Col>
            <Col md={4}>
              <Card
                statsIcon="fa fa-clock-o"
                title="Statisques de concurrence"
                category="SODIGAZ, ENORA, SANOL, TOTAL"
                stats="Mis à jour il y a 24h"
                content={
                  <div
                    id="chartPreferences"
                    className="ct-chart ct-perfect-fourth"
                  >
                    <ChartistGraph data={dataPie} type="Pie" />
                  </div>
                }
                legend={
                  <div className="legend">{this.createLegend(legendPie)}</div>
                }
              />
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Card
                id="chartActivity"
                title="Statistiques d'expension"
                category="Les prospects et Nouvels PDV"
                stats="Des données de notre API"
                statsIcon="fa fa-check"
                content={
                  <div className="ct-chart">
                    <ChartistGraph
                      data={dataBar}
                      type="Bar"
                      options={optionsBar}
                      responsiveOptions={responsiveBar}
                    />
                  </div>
                }
                legend={
                  <div className="legend">{this.createLegend(legendBar)}</div>
                }
              />
            </Col>
            <Col md={6}>
            <Card
                id="stockActivity"
                title="Suivi gloabal du stock de gaz"
                category="Stock de gaz vu mensuellement"
                stats="Des données de notre API"
                statsIcon="fa fa-check"
                content={
                  <div className="ct-chart">
                    <ChartistGraph
                      data={dataBar}
                      type="Bar"
                      options={optionsBar}
                      responsiveOptions={responsiveBar}
                    />
                  </div>
                }
                legend={
                  <div className="legend">{this.createLegend(legendBar)}</div>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default Dashboard;
