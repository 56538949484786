/*!

=========================================================
* Light Bootstrap Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React,{ Component } from "react";
// react components used to create a google map
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";
//import axios from 'axios';
import AuthService from "services/auth.service";
import { getArrivee, getDepart, getPDVGazVisites, getProspections} from '../api/crmAPI';
class Itineraires extends Component { 

  constructor() {
    super();
    this.state = { 
      entrepots: 
      [
        {"id":6.1636083,"latitude":6.1636083,"longitude":1.3164231,"denomination":"Siège SODIGAZ"}
      ],
      transports: [],
      itineraires: [],
      departs:[],
      arrivees:[],
      zones_prospectees:[],
      zones_visitees:[],
      date_maintenant: "2020-11-04 23:00:00",
      date_zeroheure: "2020-11-04 00:00:00"
    };
  }
  componentDidMount() {
    const currentUser = AuthService.getCurrentUser();
    this._getArrivee = this._getArrivee.bind(this);
    this._getDepart = this._getDepart.bind(this);
    this._getPDVVisites = this._getPDVVisites.bind(this);
    this._getProspections = this._getProspections.bind(this);
    this._getItineraires = this._getItineraires.bind(this);
    if(currentUser){
      this._getArrivee();
      this._getDepart();
      this._getProspections();
      this._getPDVVisites();
      this._getItineraires();
    }
  }
  _getDepart(){
    const periode={"debut":this.state.date_zeroheure.toString(),"fin":this.state.date_maintenant.toString(),"id": 7};
      getDepart(periode).then(data => {
        console.log(data);
        //ata.filter((pdvreleve,index)=>pdvreleve == null);
        data.map((item, key)=>{
          const point = {"lat": item.latitude,"long":item.longitude,"zone":"Depart"};
          let dataTemp = this.state.departs;
          dataTemp.push(point);
          this.setState({departs: [...dataTemp]});
        });
        console.log(this.state.departs);
        this._getItineraires(this.state.departs);
      })
  }
  _getArrivee(){
    const periode={"debut":this.state.date_zeroheure.toString(),"fin":this.state.date_maintenant.toString(),"id": 7};
      getArrivee(periode).then(data => {
        //console.log(data);
        //ata.filter((pdvreleve,index)=>pdvreleve == null);
        console.log(data);
        //ata.filter((pdvreleve,index)=>pdvreleve == null);
        data.map((item, key)=>{
          const point = {"lat": item.latitude,"long":item.longitude,"zone":"Arrivée"};
          let dataTemp = this.state.arrivees;
          dataTemp.push(point);
          this.setState({arrivees: [...dataTemp]});
        });
        console.log(this.state.arrivees);
        this._getItineraires(this.state.arrivees);
      })
  }
  _getProspections(){
    const periode={"debut":this.state.date_zeroheure.toString(),"fin":this.state.date_maintenant.toString(),"id": 7};
      getProspections(periode).then(data => {
        //console.log(data);
        //ata.filter((pdvreleve,index)=>pdvreleve == null);
        console.log(data);
        //ata.filter((pdvreleve,index)=>pdvreleve == null);
        data.map((item, key)=>{
          const point = {"lat": item.latitude_prospecte,"long":item.longitude_prospecte,"zone":item.entrepot ? item.entrepot.denomination:"Prospect"};
          let dataTemp = this.state.zones_prospectees;
          dataTemp.push(point);
          this.setState({zones_prospectees: [...dataTemp]});
        });
        console.log(this.state.zones_prospectees);
        this._getItineraires(this.state.zones_prospectees);
      })
  }
  _getPDVVisites(){
    const periode={"debut":this.state.date_zeroheure.toString(),"fin":this.state.date_maintenant.toString(),"id": 7};
      getPDVGazVisites(periode).then(data => {
        console.log(data);
        //ata.filter((pdvreleve,index)=>pdvreleve == null);
        data.map((item, key)=>{
          const point = {"lat": item.latitude_visite,"long":item.longitude_visite,"zone":item.entrepot ? item.entrepot.denomination:"---"};
          let dataTemp = this.state.zones_visitees;
          dataTemp.push(point);
          this.setState({zones_visitees: [...dataTemp]});
        });
        console.log(this.state.zones_visitees);
        this._getItineraires(this.state.zones_visitees);
      })
  }
  _getItineraires(pointsList=[]){
    pointsList.map((item, key)=>{
      const point = {"lat": item.lat,"long":item.long,"zone":item.zone};
      const itinerairesTempToday = this.state.itineraires;
      itinerairesTempToday.push(point);
      this.setState({itineraires:[...itinerairesTempToday]});
    });
    console.log(this.state.itineraires);
  }
  mapMarkers = () => {
    return this.state.itineraires.map((entrepot,index) =>
        <Marker
          key={index}
          position={{ lat: entrepot.lat, lng: entrepot.long }}
          title={entrepot.zone}
        />
      )
  }  
  render() {
    const CustomMap = withScriptjs(
      withGoogleMap(props => (
        <GoogleMap
          defaultZoom={13}
          defaultCenter={{ lat: 6.1636083, lng: 1.3164231 }}
          defaultOptions={{
            scrollwheel: false,
            zoomControl: true
          }}
        >
         {this.mapMarkers()}
        </GoogleMap>
      ))
    ); 
  return(
    <CustomMap
      googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDuo5jfqMzHnUMkoNZAwvRUIAGaDFHEZaQ"
      loadingElement={<div style={{ height: `100%` }} />}
      containerElement={<div style={{ height: `100vh` }} />}
      mapElement={<div style={{ height: `100%` }} />}
    />
  );   
}
};
export default Itineraires;