export default function authHeader() {
  const user = JSON.parse(localStorage.getItem('user'));
  //console.log(user.accessToken);
  if (user && user.userToken) {
     return { "Authorization": `Bearer ${user.userToken}` }; // for Spring Boot back-end
    //return { 'Authorization': user.accessToken };       // for Node.js Express back-end
  } else {
    return {};
  }
}
