import React, { Component } from "react"; 
import DataTable from 'react-data-table-component';
import AuthService from "services/auth.service";
import haversine from 'haversine-distance';
import Moment from "moment";
import DatePicker from "react-date-picker";
import { CSVLink } from "react-csv";
import "react-datepicker/dist/react-datepicker.css";
import { extendMoment } from 'moment-range';
import { getUsersByRole,getArrivee, getDepart, getPDVGazVisites, getProspections,getItinerairesByUserByPeriod} from '../api/crmAPI';
const moment = extendMoment(Moment);

const headers = [
  { label: "Date de descente", key: "quand" },
  { label: "Kilométrage", key: "distances" },
  { label: "Activités", key: "zones" },
];

const columns = [
  {
    name: 'Date',
    selector: 'quand',
    sortable: true,
  },
  {
    name: 'Kilométrage',
    selector: 'distances',
    sortable: true,
  },
  {
    name: 'Zones parcourues',
    selector: 'zones',
    sortable: true,
  }
];

class FicheTransport extends Component {
  
  constructor() {
    super();
    this.state = { 
      transports: [],
      itineraires: [],
      departs:[],
      arrivees:[],
      zones_prospectees:[],
      zones_visitees:[],
      date_maintenant: "2020-11-04 23:00:00",
      date_zeroheure: "2020-11-04 00:00:00",
      startDate: "",
      endDate: "",
      idCommercial:null,
      currentUser: {},
      commerciaux:[]
    };

  }
  componentDidMount() {
    const currentUser = AuthService.getCurrentUser();
    this._buildItinerairesDates = this._buildItinerairesDates.bind(this);
    this._getDistanceEntre2Points = this._getDistanceEntre2Points.bind(this);
    this._getKilometrageDuJour = this._getKilometrageDuJour.bind(this);
    this._getZonesDuJour = this._getZonesDuJour.bind(this);
    this._getTransports = this._getTransports.bind(this);
    
    this.setState({currentUser: currentUser});
    //this._getPDVVisitesByDateByCommercial = this._getPDVVisitesByDateByCommercial.bind(this);
    this._getListCommerciaux = this._getListCommerciaux.bind(this);
    this._getReleveByDate = this._getReleveByDate.bind(this);
    this._setIDCommercial = this._setIDCommercial.bind(this);
    this._setStartDate = this._setStartDate.bind(this);
    this._setEndDate = this._setEndDate.bind(this);
    this._onSubmitReleveStocks = this._onSubmitReleveStocks.bind(this);
    this._printCSVButton = this._printCSVButton.bind(this);
    this._printDataTable = this._printDataTable.bind(this);
    this._printheaderComponent = this._printheaderComponent.bind(this);
    //this._onChange =  this._onChange.bind(this);
    this._listCommerciaux = this._listCommerciaux.bind(this);
    if(currentUser){
      this._buildItinerairesDates();
      this._getListCommerciaux();
    }
  }

  _getTransports(){
    const {itineraires} = this.state;
    itineraires.map((item, key)=>{
      let kilometrage = this._getKilometrageDuJour(item.itineraires);
      let zones = this._getZonesDuJour(item.itineraires);
      let quand = moment(item.date).format('DD-MM-YYYY');
      const transport = {"quand":quand,"zones":zones,"distances":kilometrage};
      const itinerairesTempToday = this.state.transports;
      itinerairesTempToday.push(transport);
      this.setState({transports:[...itinerairesTempToday]});
    });
    
    //console.log(this.state.transports);
  }

  _buildItinerairesDates(){
    const {currentUser} = this.state;
    const {idCommercial} = this.state;
    const dates = [moment(this.state.startDate, 'YYYY-MM-DD'), moment(this.state.endDate, 'YYYY-MM-DD')];
    const listdates = moment().range(dates);
    const jours = Array.from(listdates.by('days'));
      jours.map(m => {
        //console.log(this.state.idCommercial);
        const dateItineraire = m.format('YYYY-MM-DD');
        const periodItineraire ={debut: dateItineraire +" 00:00:01",fin: dateItineraire +" 23:59:59",id:Number(idCommercial?idCommercial:currentUser.userID)};
        getItinerairesByUserByPeriod(periodItineraire).then(data => {
          //console.log(data);
          if(data.length  >= 2 ){
            //console.log("Toto");
            const ligneItineraire = [];
            const itineraires = {"date": dateItineraire,"itineraires":data};
            ligneItineraire.push(itineraires);
            this.setState({itineraires: [...ligneItineraire]});
            console.log(this.state.itineraires);
            this._getTransports();
          }
        });
  
      });
    
  }
  _getDistanceEntre2Points(point1={},point2={}){
    const distance =haversine(point1,point2);
    return distance/1000;
  }
  _getLigneTransport(){
    this._getTransports();
  }
  _getKilometrageDuJour(itineraires=[]){
    let kilometrage=0;
    let distance=0;
    let sizePoints= itineraires.length;
    for(var i=0;i < sizePoints;i++){
      var currentPoint=itineraires[i];
      console.log(currentPoint);
      var nextPoint=itineraires[i===sizePoints-1?sizePoints-1:i+1];     
      const point1={latitude:currentPoint.latitude,longitude:currentPoint.longitude};
      const point2= {latitude:nextPoint.latitude,longitude:nextPoint.longitude};
      distance = this._getDistanceEntre2Points(point1,point2);
      kilometrage += distance;
    }
    return kilometrage;
  }
  _getZonesDuJour(itineraires=[]){
    let zones="";
    let sizePoints= itineraires.length;
    for(var i=0;i < sizePoints;i++){
      var currentPoint=itineraires[i];
      var nextPoint=itineraires[i===sizePoints-1?sizePoints-1:i+1];
      if(moment(nextPoint.created_at).format("YYYY-MM-DD")===moment(currentPoint.created_at).format("YYYY-MM-DD")){
        zones += " > "+currentPoint.zone;
      }
    }
    return zones;
  }
  _printCSVButton(){
    const {transports} = this.state;
    return (
      <CSVLink data={transports} headers={headers}>
          Exporter en excel
      </CSVLink>
    )
  }

  _printDataTable(data){
    return(
      <DataTable
          columns={columns}
          data={data}
          pagination
        />
    )
  }
  _listCommerciaux(){
    const {commerciaux} = this.state;
    return(commerciaux.map((commercial,index) =>(
      <option value={commercial.id} key={commercial.id}>{commercial.name}</option>
    )))
  }
  _getReleveByCommercial(){
    const { currentUser } = this.state;
    if(Number(currentUser.userRole) >=3 ){
      return (
        <>
          <select onChange={this._setIDCommercial}>
            <option value="1">Par commercial</option>
            {this._listCommerciaux()}
          </select>
        </>
      )
    }
    
  }
  _getListCommerciaux(){
    getUsersByRole(2).then(data => {
      console.log(data);
      //data.filter((pdvreleve,index)=>pdvreleve == null);
      this.setState({commerciaux:[...data]});
    })
  }
  _onSubmitReleveStocks(){
    const { currentUser } = this.state;
    console.log("ID Commercial "+this.state.idCommercial);
    console.log("Date de debut: "+ moment(this.state.startDate).format("YYYY-MM-DD")+" 00:00:01");
    console.log("Date de fin: "+ moment(this.state.endDate).format("YYYY-MM-DD")+" 23:59:59");
    if(Number(currentUser.userRole) <=2 ){
      this.setState({ idCommercial: currentUser.userID }, () => {
        console.log("ID Commercial "+this.state.idCommercial);
        const periode ={"id":this.state.idCommercial,"fin": moment(this.state.endDate).format("YYYY-MM-DD")+" 23:59:59","debut":moment(this.state.startDate).format("YYYY-MM-DD")+" 00:00:01"};
        this._getPDVVisitesByDateByCommercial(periode);
          let dataTemp = this.state.entrepots;
          this.setState({entrepots:[...dataTemp]});
      });
    } else {
      console.log("Je suis ici");
      const periode ={"id":this.state.idCommercial,"fin": moment(this.state.endDate).format("YYYY-MM-DD")+" 23:59:59","debut":moment(this.state.startDate).format("YYYY-MM-DD")+" 00:00:01"};
      this.setState({entrepots: []});
      this._getPDVVisitesByDateByCommercial(periode);
      let dataTemp = this.state.entrepots;
      this.setState({entrepots: dataTemp});
      //.this.setState({entrepots: entrepots});
    }
    
  }
  _setIDCommercial(event=""){
    const { currentUser } = this.state;
    if(Number(currentUser.userRole) >=3 ){
      this.setState({ idCommercial: event.target.value }, () => {
        console.log("ID Commercial "+this.state.idCommercial);
      });
    } else {
      this.setState({ idCommercial: Number(currentUser.userID) }, () => {
        console.log("ID Commercial "+this.state.idCommercial);
      });
    }
    
  }
  _setStartDate(date){
    this.setState({startDate:date});
    //this._getPDVVisitesByDateByCommercial();
  }
  _setEndDate(date){
    this.setState({endDate:date});
    //this._getPDVVisitesByDateByCommercial();
  }
  _getReleveByDate(){   
    return (
      <>
        <DatePicker onChange={(date) => this._setStartDate(date)} value={this.state.startDate}/>
        <DatePicker onChange={(date) => this._setEndDate(date)} value={this.state.endDate}/>
      </>
    );
  }

  _putValidationButton(){   
    return (
      <>
        <button type="submit" onClick={this._buildItinerairesDates}>Valider</button>
      </>
      
    );
  }
  _printheaderComponent(){
    return (
    <div className="form-inline">
      {this._getReleveByCommercial()}
      {this._getReleveByDate()}
      {this._putValidationButton()}
    </div>
    )
  }
  render() {
    const {transports} = this.state;
    return (
      <div className="content">
        <>
          {this._printCSVButton()}
          {this._printheaderComponent()}
        </>
        {this._printDataTable(transports)}
      </div>
    )
  }
}
export default FicheTransport;