/*!

=========================================================
* Light Bootstrap Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React,{ Component } from "react";
// react components used to create a google map
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";
//import axios from 'axios';
import { getDixRecentsPDVGaz} from '../api/crmAPI';
class Map extends Component { 
  
  constructor() {
    super();
    this.state = { 
      entrepots: 
        [
          {"id":6.1636083,"latitude":6.1636083,"longitude":1.3164231,"denomination":"Siège SODIGAZ"}
        ],
      open:false 
      };
  }
  componentDidMount() {
    getDixRecentsPDVGaz().then(data => {
      //console.log(data);
      this.setState({
        entrepots:[...data]
      });
    })
  }
  mapMarkers = () => {
    return this.state.entrepots.map((entrepot) =>
        <Marker
          key={entrepot.id}
          position={{ lat: entrepot.latitude, lng: entrepot.longitude }}
          title={entrepot.denomination}
          description={"Nous vous apportons l'énergie"}
          >
        </Marker>
      )
  }  
  render() {
    const CustomMap = withScriptjs(
      withGoogleMap(props => (
        <GoogleMap
          defaultZoom={13}
          defaultCenter={{ lat: 6.1636083, lng: 1.3164231 }}
          defaultOptions={{
            scrollwheel: false,
            zoomControl: true
          }}
        >
         {this.mapMarkers()}
        </GoogleMap>
      ))
    ); 
  return(
    <CustomMap
      googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDuo5jfqMzHnUMkoNZAwvRUIAGaDFHEZaQ"
      loadingElement={<div style={{ height: `100%` }} />}
      containerElement={<div style={{ height: `100vh` }} />}
      mapElement={<div style={{ height: `100%` }} />}
    />
  );   
}
};
export default Map;
