/*!

=========================================================
* Light Bootstrap Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React,{ Component } from "react";
// react components used to create a google map
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";
//import axios from 'axios';
import { getProspections} from '../api/crmAPI';
class CartePDVReleves extends Component { 
  state = { 
    entrepots: [] 
    };
  constructor() {
    super();
  }
  componentDidMount() {
    const periode={debut:"2020-11-04 00:00:00",fin:"2020-11-04 23:00:00",id:7};
    getProspections(periode).then(data => {
      console.log(data);
      data.filter((pdvreleve,index)=>pdvreleve == null);
      this.setState({
        entrepots:[...data]
      });
    })
  }
  mapMarkersReleves = () => {
    return this.state.entrepots.map((entrepot) =>
        <Marker
          key={entrepot.id}
          position={{ lat: entrepot.latitude_prospecte, lng: entrepot.longitude_prospecte }}
          title={entrepot.prospect?entrepot.prospect.name:""}
          icon={"http://maps.google.com/mapfiles/ms/icons/blue-dot.png"}
        />
      )
  }
  render() {
    const CustomMap = withScriptjs(
      withGoogleMap(props => (
        <GoogleMap
          defaultZoom={15}
          defaultCenter={{ lat: 6.1636083, lng: 1.3164231 }}
          defaultOptions={{
            scrollwheel: false,
            zoomControl: true
          }}
        >
         {this.mapMarkersReleves()}
        </GoogleMap>
      ))
    ); 
  return(
    <CustomMap
      googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDuo5jfqMzHnUMkoNZAwvRUIAGaDFHEZaQ"
      loadingElement={<div style={{ height: `100%` }} />}
      containerElement={<div style={{ height: `100vh` }} />}
      mapElement={<div style={{ height: `100%` }} />}
    />
  );   
}
};
export default CartePDVReleves;
