import axios from 'axios';
import authHeader from '../services/auth-header';
//Recuperer un point de vente, Gaz par son Nom
export function getPDVbyName(pdvName) {
  const url = "https://api.sodigaz.tg/public/graphql?query={entrepot(denomination:"+'"%'+pdvName+'%"'+"){id,denomination}}";
  return fetch(url)
    .then((response) => response.json())
    .catch((error) => console.error(error))
}
export async function setAuthorizationToken(token) {
  if(token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
      delete axios.defaults.headers.common['Authorization'];
  }
}
//Recuperer les informations basiques sur le User connecté
export async function getMoi() {
  const url = "https://api.sodigaz.tg/public/graphql?query={moi{id,role,name,email}}";
  console.log(url);
        return axios.get(url,{headers: authHeader()})
        .then((response) => response.data.data.moi)
        .catch((error) => console.error(error))
}

export async function seConnecter(logindata) {
  const url = "https://api.sodigaz.tg/public/graphql?query=mutation{connecter(email:"+'"'+logindata.username+'"'+","+"password:"+'"'+logindata.password+'"'+")}";
  console.log(url);
  return fetch(url)
    .then((response) => response.json())
    .catch((error) =>console.error(error))
}


export async function getDixRecentsPDVGaz() {
  const url = "https://api.sodigaz.tg/public/graphql?query={entrepots(first:20){paginatorInfo{count,total},data{id,denomination,latitude,longitude,user{name,email}}}}";
  return axios.get(url).then((response) =>
      response.data.data.entrepots.data
      )
      .catch((error) => console.error(error))
}

export async function getPDVGazReleves(periode) {
  const url = "https://api.sodigaz.tg/public/graphql?query={gis_entrepots_releves_byDate(produit_id:2,date_debut_releve:{from:"+'"'+periode.debut+'"'+",to:"+'"'+periode.fin+'"'+"}){id,latitude_releve,longitude_releve,observations,entrepot{id,denomination,latitude,longitude}}}";
  return axios.get(url,{headers: authHeader()}).then((response) =>
      response.data.data.gis_entrepots_releves_byDate
      )
      .catch((error) => console.error(error))
}

export async function getPDVGazVisites(periode) {
  const url = "https://api.sodigaz.tg/public/graphql?query={itineraires_visites_pdv_byDate_byUser(date_debut:{from:"+'"'+periode.debut+'"'+",to:"+'"'+periode.fin+'"}'+",user_id:"+periode.id+"){qsdg6,qsdg12,qsdg50,qsdg3,observations,date_debut,date_fin,qenr6,qenr12,latitude_visite,longitude_visite,entrepot{denomination,latitude,longitude}}}";
  console.log(url);
  return axios.get(url).then((response) =>
      response.data.data.itineraires_visites_pdv_byDate_byUser
      )
      .catch((error) => console.error(error))
}

export async function getUsersByRole(role) {
  const url = "https://api.sodigaz.tg/public/graphql?query={commerciaux(role:"+role+"){id,name,role}}";
  console.log(url);
  return axios.get(url).then((response) =>
      response.data.data.commerciaux
      )
      .catch((error) => console.error(error))
}

export async function getItineraires(periode) {
  const url = "https://api.sodigaz.tg/public/graphql?query={gis_entrepots_releves_byDate(produit_id:2,date_debut_releve:{from:"+'"'+periode.debut+'"'+",to:"+'"'+periode.fin+'"'+"}){id,latitude_releve,longitude_releve,observations,entrepot{id,denomination,latitude,longitude}}}";
  return axios.get(url,{headers: authHeader()}).then((response) =>
      response.data.data.gis_entrepots_releves_byDate
      )
      .catch((error) => console.error(error))
}

export async function getItinerairesByUserByPeriod(periode) {
  const url = "https://api.sodigaz.tg/public/graphql?query={itineraires_byDate_byUser(user_id:"+periode.id+",created_at:{from:"+'"'+periode.debut+'"'+",to:"+'"'+periode.fin+'"'+"}){id,latitude,longitude,zone,created_at}}";
  console.log(url);
  return axios.get(url,{headers: authHeader()}).then((response) =>
      response.data.data.itineraires_byDate_byUser
      )
      .catch((error) => console.error(error))
}
export async function getProspections(periode) {
  const url = "https://api.sodigaz.tg/public/graphql?query={itineraires_prospections_byDate_byUser(user_id:"+periode.id+",created_at:{from:"+'"'+periode.debut+'"'+",to:"+'"'+periode.fin+'"'+"}){id,latitude_prospecte,longitude_prospecte,created_at,prospect{id,name}}}";
  return axios.get(url).then((response) =>
      response.data.data.itineraires_prospections_byDate_byUser
      )
      .catch((error) => console.error(error))
}

export async function getDepart(periode) {
  const url = "https://api.sodigaz.tg/public/graphql?query={depart_byDate_byUser(user_id:"+periode.id+",denomination:"+'"Depart"'+"created_at:{from:"+'"'+periode.debut+'"'+",to:"+'"'+periode.fin+'"'+"}){id,latitude,longitude,denomination,created_at}}";
  console.log(url);
  return axios.get(url).then((response) =>
      response.data.data.depart_byDate_byUser
      )
      .catch((error) => console.error(error))
}

export async function getArrivee(periode) {
  const url = "https://api.sodigaz.tg/public/graphql?query={depart_byDate_byUser(user_id:"+periode.id+",denomination:"+'"Arrivee"'+"created_at:{from:"+'"'+periode.debut+'"'+",to:"+'"'+periode.fin+'"'+"}){id,latitude,longitude,denomination,created_at}}";
  console.log(url);
  return axios.get(url).then((response) =>
      response.data.data.depart_byDate_byUser
      )
      .catch((error) => console.error(error))
}

export async function changerMotPasse(logindata) {
  const url = "https://api.sodigaz.tg/public/graphql?query=mutation{updateMotPasse(id:"+logindata.userID+","+"email:"+'"'+logindata.emailpro+'"'+","+"password:"+'"'+logindata.password+'"'+"){id}}";
  console.log(url);
  setAuthorizationToken(logindata.token);
  return axios.post(url)
        .then((response) => response.data)
        .catch((error) => console.error(error))
}


export async function getEffectifPDVGaz(logos) {
  const url = "https://api.sodigaz.tg/public/graphql?query={entrepots_type(first:1,includeLogos:"+'['+logos+']'+"){paginatorInfo{total}}}";
  console.log(url);
  return axios.post(url)
        .then((response) => response.data.data.entrepots_type.paginatorInfo.total)
        .catch((error) => console.error(error))
}

export async function getEffectifPDVReleve() {
  const url = "https://api.sodigaz.tg/public/graphql?query={pdv_releves_total}";
  console.log(url);
  return axios.post(url)
        .then((response) => response.data.data.pdv_releves_total)
        .catch((error) => console.error(error))
}
export async function getEffectifProspect() {
  const url = "https://api.sodigaz.tg/public/graphql?query={prospects_total}";
  console.log(url);
  return axios.post(url)
        .then((response) => response.data.data.prospects_total)
        .catch((error) => console.error(error))
}

export async function getEffectifProspections() {
  const url = "https://api.sodigaz.tg/public/graphql?query={prospections_total}";
  console.log(url);
  return axios.post(url)
        .then((response) => response.data.data.prospections_total)
        .catch((error) => console.error(error))
}


